import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Bryllup - Fest</h1>
    <div>Festen komer til at foregå i Ladding forsamlingshus.</div>
    <div>Man kan komme dertil med bil eller bus.</div>
    <div>Måske kan vi hjælpe med at arrangere at man kan gøre med folk?</div>
    <Link to="/">Tilbage til oversigt</Link>
  </Layout>
);

export default IndexPage;
